import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import LoadingBar from '../loadingbar/loadingbar';
import './fileuploader.css';
import SummaryDisplay from '../summary/summarydisplay';
import RatingReview from '../ratingreview/RatingReview';

/**
 * FileUploader component for uploading and summarizing PDF files.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.onSummaryReceived - Callback function to handle the received summary data.
 * @returns {JSX.Element} The FileUploader component.
 */
function FileUploader({ onSummaryReceived, onFileUpload, onSummarizeClick }) {
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(null);
  const [summaryData, setSummaryData] = useState(null);
  const [rating, setRating] = useState(0)

  // Retrieve name from local storage
  const savedemail = localStorage.getItem('useremail');

  const onDrop = useCallback((acceptedFiles) => {
    const uploadedFile = acceptedFiles[0];

    // Check if the file is from a local source
    if (uploadedFile && uploadedFile.path) {
      setFile(uploadedFile);
      setProgress(0);
      setError(null);
      setSummaryData(null);
    } else {
      setError('Please upload a file from your local system.');
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': ['.pdf']
    },
    multiple: false
  });

  /**
   * Handles the process of summarizing a file.
   * 
   * @async
   * @function handleSummarize
   * @returns {Promise<void>} A Promise that resolves when the summarization process is complete.
   */
  const handleSummarize = async () => {
    if (!file) return;

    // Check if user has enough credits
    const canProceed = await onSummarizeClick();
    if (!canProceed) {
      return; // Stop here if not enough credits
    }

    setIsLoading(true);
    setProgress(0);
    setError(null);

    const formData = new FormData();
    formData.append('file', file);
    formData.append('email', savedemail);

    try {
      const response = await axios.post('/api/summarize', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setProgress(percentCompleted);
        }
      });

      setIsLoading(false);
      setSummaryData(response.data);
      onSummaryReceived(response.data);
    } catch (error) {
      console.error('Error summarizing PDF:', error);
      setIsLoading(false);

      let errorMessage = 'An unexpected error occurred. Please try again.';

      if (error.response) {
        errorMessage = error.response.data.message || `Server error: ${error.response.status}`;
      } else if (error.request) {
        errorMessage = 'No response received from the server. Please check your connection.';
      } else {
        errorMessage = error.message;
      }

      setError(errorMessage);
    }
  };

  return (
    <div className="file-uploader">
      <div className="uploader-container" {...getRootProps()} role="button" aria-label="File Upload Area">
        <input {...getInputProps()} aria-label="File Input" />
        <div className="upload-icon" aria-hidden="true">📄</div>
        {isDragActive ? (
          <p className="upload-text">Drop your Resume PDF here...</p>
        ) : (
          <p className="upload-text">Drag and drop a Resume PDF file to summarize</p>
        )}
        <p>Or</p>
        <button className="choose-file-button">+ Choose Resume File</button>
      </div>
      {file && (
        <div className="file-info">
          <p>Selected file: {file.name}</p>
          <p>Last modified: {new Date(file.lastModified).toLocaleString()}</p>
        </div>
      )}
      <button
        className="summarize-button"
        onClick={handleSummarize}
        disabled={!file || isLoading}
      >
        {isLoading ? 'Generating...' : `Generate Questions based on ${file ? `"${file.name}"` : 'Resume'}`}
      </button>
      {isLoading && (
        <div className="loading-bar-container">
          <LoadingBar progress={progress} />
        </div>
      )}
      {error && <p className="error-message">{error}</p>}
      <SummaryDisplay file={file} summaryData={summaryData} />
      {summaryData && (
        <RatingReview rating={rating} setRating={setRating} />
      )}
    </div>
  );
}

export default FileUploader;
