import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import './header.css';
import img from "../../image.webp";
import axios from 'axios';

const Header = ({ onLogout }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [credits, setCredits] = useState('0');
  const user = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    const fetchCredits = async () => {
      try {
        const response = await axios.get('/api/user_data/credits', {
          params: { email: user.email }
        });
        console.log(response.data);
        setCredits(response.data.credits);
      } catch (error) {
        console.error('Error fetching credits:', error);
        if (error.response) {
          console.error('Response data:', error.response.data);
          console.error('Response status:', error.response.status);
        }
      }
    };

    if (user) {
      fetchCredits();
    }
  }, [user]);

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="header">
      <img src={img} alt="Icon" className="user-icon" />
      <nav>
        {user && (
          <>
            <NavLink to="/summarize" className="nav-link" activeClassName="active">
              Resume questions
            </NavLink>
            <NavLink to="/buy-credits" className="nav-link" activeClassName="active">
              Buy Credits
            </NavLink>
          </>
        )}
      </nav>
      {user ? (
        <div className="user-menu">
          <img
            src={user.imageUrl}
            alt="Profile"
            className="user-icon"
            onClick={handleMenuToggle}
          />
          {menuOpen && (
            <div className="menu-dropdown">
              <img src={user.imageUrl} alt="Profile" className="profile-pic" />
              <p>{user.name}</p>
              <p>{user.email}</p>
              <p>Credits: {credits}</p>
              <button onClick={onLogout}>Logout</button>
            </div>
          )}
        </div>
      ) : (
        <Link to="/login"><button>Login</button></Link>
      )}
    </div>
  );
};

export default Header;
