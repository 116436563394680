import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './buycredits.css';

function BuyCredits() {
  const user = JSON.parse(localStorage.getItem('user'));
  const navigate = useNavigate();

  const [existingCredits, setExistingCredits] = useState(0);
  const [purchaseCredits, setPurchaseCredits] = useState(10);
  const costPerCredit = 1; // Cost per credit

  useEffect(() => {
    const fetchUserCredits = async () => {
      try {
        const response = await axios.get('/api/user_data/credits', {
          params: { email: user.email }
        });
        setExistingCredits(response.data.credits);
      } catch (error) {
        console.error('Error fetching user credits:', error);
      }
    };

    fetchUserCredits();
  }, [user.email]);

  const handleCreditsChange = (e) => {
    const newCredits = Number(e.target.value);
    setPurchaseCredits(newCredits);
  };

  const handleBuyCredits = async () => {
    const totalCost = purchaseCredits * costPerCredit;

    try {
      const response = await axios.post('/api/create-order', {
        amount: totalCost * 100, // Amount in paise
        currency: 'INR',
        receipt: `receipt_${Date.now()}`,
        notes: {
          user_email: user.email,
          credits: purchaseCredits,
        },
      });

      const { orderId } = response.data;

      const options = {
        key: 'rzp_live_HPPXiI4AC9uwvo', // Replace with your Razorpay key
        amount: totalCost * 100, // Amount in paise
        currency: 'INR',
        name: 'Buy Credits',
        description: 'Purchase Credits',
        order_id: orderId,
        handler: async (response) => {
          // Handle successful payment
          await axios.post('/api/verify-payment', {
            order_id: orderId,
            payment_id: response.razorpay_payment_id,
          });

          // Update user's credits
          const updatedCredits = existingCredits + purchaseCredits;
          await axios.post('/api/update-user-credits', {
            email: user.email,
            credits: updatedCredits,
          });

          // Update local state
          setExistingCredits(updatedCredits);
          alert('Payment successful! Your credits have been updated.');
          navigate('/summarize'); // Redirect to summarize page
        },
        prefill: {
          name: user.name,
          email: user.email,
        },
        theme: {
          color: '#F37254',
        },
      };

      const rzp = new window.Razorpay(options);
      rzp.open();
    } catch (error) {
      console.error('Error creating order:', error);
      alert('There was an error initiating the payment. Please try again.');
    }
  };

  return (
    <div className="buy-credits-container">
      <h2>Hi {user.name}, buy your credits</h2>
      <p>Your current credits: {existingCredits}</p>
      <label htmlFor="credits">Select Credits to Purchase:  </label>
      <select id="credits" value={purchaseCredits} onChange={handleCreditsChange} className="credit-selector">
        <option value="10">10 Credits</option>
        <option value="20">20 Credits</option>
        <option value="30">30 Credits</option>
      </select>
      <p>Total Cost: {purchaseCredits * costPerCredit} ₹ </p>
      <p></p>
      <div className="button-container">
        <button className="buy-button" onClick={handleBuyCredits}>
          Buy Credits
        </button>
      </div>
    </div>
  );
}

export default BuyCredits;