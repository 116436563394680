import React from 'react';
import { jwtDecode } from 'jwt-decode';
import { GoogleLogin } from '@react-oauth/google';
import './login.css';
import axios from 'axios';

const Login = ({ onLogin }) => {
  const handleLoginSuccess = async (credentialResponse) => {
    console.log(credentialResponse);

    const decoded = jwtDecode(credentialResponse.credential);
    console.log(decoded);

    try {
      // Fetch user data from the backend
      const response = await axios.get('/api/user_data', {
        params: { email: decoded.email }
      });

      const userData = response.data;
      userData.imageUrl = decoded.picture;

      // Save user data to local storage
      localStorage.setItem('user', JSON.stringify(userData));
      localStorage.setItem('useremail', userData.email);
      localStorage.setItem('credits', userData.credits);

      console.log('User data fetched from backend:', userData);
      onLogin(userData);
    } catch (error) {
      console.error('Error fetching user data from backend:', error);
      // Fallback to using decoded data if fetch fails
      const fallbackUserData = {
        name: decoded.name,
        email: decoded.email,
        imageUrl: decoded.picture,
        credits: 2,
      };

      try {
        const response = await axios.post('/api/user_data', fallbackUserData, {
          headers: {
            'Content-Type': 'application/json',
          }
        });
        console.log('User data sent to backend:', response.data);
        localStorage.setItem('user', JSON.stringify(fallbackUserData));
        localStorage.setItem('useremail', fallbackUserData.email);
        localStorage.setItem('credits', fallbackUserData.credits);
        onLogin(fallbackUserData);
      } catch (error) {
        console.error('Error sending user data to backend:', error);
      }
    }
  };

  const handleLoginFailure = (response) => {
    console.error(response);
  };

  return (
    <div className="login-container">
      <h2 className="login-title">Log in to continue generating questions from your Resume</h2>
      <GoogleLogin
        onSuccess={handleLoginSuccess}
        onFailure={handleLoginFailure}
        cookiePolicy={'single_host_origin'}
        className="login-google-button"
        useOneTap
        size="large"
        theme="filled_blue"
        shape="rectangular"
      />
    </div>
  );
};

export default Login;
