import React from 'react';
import './loadingbar.css';

/**
 * Renders a loading bar component.
 *
 * @param {Object} props - The component props.
 * @param {number} props.progress - The progress value for the loading bar (0-100).
 * @returns {JSX.Element} The loading bar component.
 */
function LoadingBar({ progress }) {
  return (
    <div className="loading-bar-container">
      <div className="loading-bar" style={{ width: `${progress}%` }}></div>
    </div>
  );
}

export default LoadingBar;