import React from 'react'

function RatingReview({ rating, setRating }) {
  return (
    <div style={{ display: 'flex' }}>
      {
        [1, 2, 3, 4, 5].map((star) => {
          return (
            <span
              className='start'
              style={{
                cursor: 'pointer',
                color: rating >= star ? 'gold' : 'gray',
                fontSize: `35px`,
                direction: 'inline'
              }}
              onClick={() => {
                setRating(star)
              }}
            >
              {' '}★{' '}
            </span>
          )
        })
      }
    </div >
  )
}

export default RatingReview;