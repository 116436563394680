import React from 'react';
import './summarydisplay.css';
import DisplayJson from '../display/DisplayJson';

/**
 * Renders the summary display component.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.file - The file object.
 * @param {Object} props.summaryData - The summary data object.
 * @returns {JSX.Element|null} The summary display component.
 */
function SummaryDisplay({ file, summaryData }) {
  if (!summaryData) return null;

  //console.log('Summary:')
  //console.log(JSON.parse(summaryData.questions))

  return (
    <div className="summary-container">
      <h2 style={{ textAlign: 'center' }}>Resume Question</h2>
      <div>
        <DisplayJson jsonData={JSON.parse(summaryData.questions)} />
      </div>
    </div>
  );
}

export default SummaryDisplay;