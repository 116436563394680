import React from 'react';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';

const DisplayJson = ({ jsonData }) => {
  const renderQuestions = (questions, isCoding) => {
    return questions.map((q, index) => (
      <ul>
        <div key={index} className="question-block">
          <li><strong>{q.Question}</strong></li>
        </div>
        <div className='answer-block'>
          {isCoding ? (
            <SyntaxHighlighter style={atomOneDark}>
              {q["Possible Answer"]}
            </SyntaxHighlighter>
          ) : (
            <p><b>Possible Answer:</b> {q["Possible Answer"]}</p>
          )}
        </div >
      </ul >
    ));
  };

  const renderSection = (sectionTitle, content) => {
    const isCodingSection = sectionTitle.includes("Coding");
    const title = sectionTitle.split("_").join(" ")
    if (Array.isArray(content)) {
      return (
        <div className="section" key={sectionTitle}>
          <h3>{title}</h3>
          {renderQuestions(content, isCodingSection)}
        </div>
      );
    } else if (typeof content === 'object') {
      return (
        <div className="section" key={sectionTitle}>
          <h3>{content.Title}</h3>
          {renderQuestions(content.Questions, isCodingSection)}
        </div>
      );
    } else {
      return (
        <div className="section" key={sectionTitle}>
          <h3>{title}</h3>
          <p>{content}</p>
        </div>
      );
    }
  };

  return (
    <div className="summary-container">
      {Object.keys(jsonData).map((key) => renderSection(key, jsonData[key]))}
    </div>
  );
};

export default DisplayJson;