import React from 'react';

const Popup = ({ message, onClose, onBuyCredits }) => (
  <div style={{
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000
  }}>
    <div style={{
      backgroundColor: 'white',
      padding: '20px',
      borderRadius: '5px',
      boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
      maxWidth: '400px',
      width: '100%',
      textAlign: 'center'
    }}>
      <p style={{ marginBottom: '20px', fontSize: '16px', color: '#333' }}>{message}</p>
      <button
        onClick={onBuyCredits}
        style={{
          backgroundColor: '#4CAF50',
          color: 'white',
          border: 'none',
          padding: '10px 20px',
          margin: '0 10px',
          borderRadius: '3px',
          cursor: 'pointer',
          fontSize: '14px'
        }}
      >
        Buy Credits
      </button>
      <button
        onClick={onClose}
        style={{
          backgroundColor: '#f44336',
          color: 'white',
          border: 'none',
          padding: '10px 20px',
          margin: '0 10px',
          borderRadius: '3px',
          cursor: 'pointer',
          fontSize: '14px'
        }}
      >
        Close
      </button>
    </div>
  </div>
);

export default Popup;