import React, { useState, useEffect } from 'react';
import { MemoryRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import axios from 'axios';
import Header from './components/headers/header';
import FileUploader from './components/fileuploader/fileuploader';
import SummaryDisplay from './components/summary/summarydisplay';
import Login from './components/login/login';
import SignUp from './components/signup/signup';
import BuyCredits from './components/credits/buycredits';
import Popup from './components/popup/popup';

import './App.css';

// LOCAL
//const clientId = '873050423616-6pnmka7t36frgd2762str0sf1phbe71j.apps.googleusercontent.com'; // Replace with your Google Client ID


// PROD
const clientId = '539092605846-l4pc32ljv4sjj9h7kqj8bb1fjd6prnfp.apps.googleusercontent.com'; // Replace with your Google Client ID

function AppContent() {
  const [user, setUser] = useState(null);
  const [summaryData, setSummaryData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [credits, setCredits] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCredits = async () => {
      if (user) {
        try {
          const response = await axios.get('/api/user_data/credits', {
            params: { email: user.email }
          });
          setCredits(response.data.credits);
        } catch (error) {
          console.error('Error fetching credits:', error);
        }
      }
    };

    fetchCredits();
  }, [user]);

  const handleSummaryReceived = (data) => {
    setIsLoading(false);
    setSummaryData(data);
  };

  const handleFileUpload = () => {
    setIsLoading(true);
    setSummaryData(null); // Clear previous summary
  };

  const handleLogin = (userData) => {
    setUser(userData);
  };

  const handleLogout = () => {
    setUser(null); // Clear user state
    localStorage.removeItem('user'); // Optionally remove user data from local storage
    navigate('/login'); // Redirect to the login page
  };

  const handleSummarizeClick = async () => {
    if (credits <= 0) {
      setShowPopup(true);
      return false; // Indicate that summarization should not proceed
    }
    return true; // Indicate that summarization can proceed
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleBuyCredits = () => {
    setShowPopup(false);
    navigate('/buy-credits');
  };

  return (
    <div className="app-container">
      <Header user={user} onLogout={handleLogout} />
      <Routes>
        <Route path="/login" element={user ? <Navigate to="/summarize" /> : <Login onLogin={handleLogin} />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/buy-credits" element={<BuyCredits />} />
        <Route path="/summarize" element={!user ? <Navigate to="/login" /> : (
          <>
            <div className="centered-content">
              <h1 className="title">Resume Based Questions</h1>
              <p className="description">
                Analyse the resume and provide possible questions which can be
                asked during an interview.
              </p>
            </div>
            <FileUploader
              onSummaryReceived={handleSummaryReceived}
              onFileUpload={handleFileUpload}
              onSummarizeClick={handleSummarizeClick}
            />
            {!isLoading && summaryData && (
              <SummaryDisplay
                fileName={summaryData.fileName}
                fileSize={summaryData.fileSize}
                summary={summaryData.summary}
                keyInsights={summaryData.keyInsights}
              />
            )}
            {showPopup && (
              <Popup
                message="You have insufficient credits. Please buy more credits to continue."
                onClose={handleClosePopup}
                onBuyCredits={handleBuyCredits}
              />
            )}
          </>
        )} />
        <Route path="/" element={<Navigate to="/login" />} />
      </Routes>
    </div>
  );
}

function App() {
  return (
    <GoogleOAuthProvider clientId={clientId}>
      <Router>
        <AppContent />
      </Router>
    </GoogleOAuthProvider>
  );
}

export default App;
