import React from 'react';

const SignUp = () => {
  return (
    <div>
      <h2>Sign Up Page</h2>
      {/* Add your sign up form here */}
    </div>
  );
};

export default SignUp;
